import { Button, Card, CardBody, Flex, FormControl, FormLabel, Textarea, useColorModeValue } from '@chakra-ui/react'
import { InfoTip } from '@unmand-systems/components'
import React, { useState } from 'react'
import { Control, Controller } from 'react-hook-form'
import tinycolor from 'tinycolor2'
import { useFormAccessibility } from '../../context/FormAccessibilityContext'
import { StyleOptions } from '../../interfaces'
import { StyleUtils } from '../../utils'
import { MarkdownPreview } from '../core/MarkdownPreview'

interface MarkdownFieldProps {
  formControlName: string
  control: Control
  error?: string
  isDisabled?: boolean
  isReadonly?: boolean
  isLabelHidden?: boolean
  isRequired?: boolean
  placeholder?: string
  helperText?: string
  title: string
  tooltipText?: string
  width?: string
  style: NonNullable<StyleOptions>
}

export const MarkdownField: React.FC<MarkdownFieldProps> = ({
  formControlName,
  control,
  error,
  isReadonly,
  isDisabled,
  isLabelHidden,
  isRequired,
  placeholder,
  helperText,
  title,
  width,
  style,
}) => {
  const { colors, inputs, layout } = StyleUtils.getTheme(style)

  const borderColor = tinycolor(layout.backgroundColor).isLight() ? 'gray.200' : 'whiteAlpha.300'
  const { getTabIndex } = useFormAccessibility()
  const [isPreview, setIsPreview] = useState<boolean>(isReadonly ? true : false)
  const placeholderColor = useColorModeValue('gray.300', 'gray.600')

  return (
    <Controller
      name={formControlName}
      control={control}
      render={({ field: controlField }) => {
        return (
          <FormControl
            isInvalid={!!error}
            isRequired={isRequired}
            height="calc(100% - 50px)"
            maxW="100%"
            flex="1"
            width={width ?? '1px'}
          >
            {!isLabelHidden && (
              <FormLabel display="flex" alignItems="center" gap="1">
                {title}
                <span className="form__optional-indicator">
                  {!isRequired && inputs.hideRequiredAsterisk && '- Optional'}
                </span>
                {helperText && <InfoTip tooltipText={helperText} />}
              </FormLabel>
            )}
            <Card
              variant={inputs.variant === 'outline' ? 'outline' : 'filled'}
              boxShadow="none"
              height="100%"
              borderColor={error ? 'red.500' : inputs.variant === 'outline' ? inputs.borderColor : 'gray.200'}
              borderWidth={inputs.variant === 'outline' ? '1px' : error ? '2px' : undefined}
              borderRadius={inputs.borderRadius}
              bg={colors.inputBgColor}
            >
              <Flex borderBottom="1px" p={2} gap="1" borderBottomColor={borderColor}>
                <Button
                  size="xs"
                  onClick={() => setIsPreview(false)}
                  isDisabled={isReadonly}
                  isActive={!isPreview}
                  {...StyleUtils.getGhostButtonStyle(colors.primaryColor)}
                >
                  Edit
                </Button>
                <Button
                  size="xs"
                  onClick={() => setIsPreview(true)}
                  isActive={isPreview}
                  {...StyleUtils.getGhostButtonStyle(colors.primaryColor)}
                >
                  Preview
                </Button>
              </Flex>
              <CardBody
                p={2}
                height="100%"
                overflowY="auto"
                bg={colors.inputBgColor}
                sx={{ color: colors.inputTextColor }}
                borderBottomRadius="md"
              >
                {!isPreview ? (
                  <Textarea
                    p={0}
                    variant="unstyled"
                    _dark={{ bg: 'gray.900' }}
                    isDisabled={isDisabled}
                    readOnly={isReadonly}
                    resize="none"
                    tabIndex={getTabIndex(formControlName)}
                    placeholder={placeholder || 'Input markdown here'}
                    outline="none"
                    border="0"
                    onChange={controlField.onChange}
                    value={controlField.value}
                    h="100%"
                    fontFamily="monospace"
                    fontSize="sm"
                    _placeholder={{
                      color: placeholderColor,
                      fontStyle: 'italic',
                    }}
                  />
                ) : (
                  <MarkdownPreview content={controlField.value}></MarkdownPreview>
                )}
              </CardBody>
            </Card>
          </FormControl>
        )
      }}
    ></Controller>
  )
}
