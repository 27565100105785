import {
  Button,
  Center,
  Flex,
  HStack,
  IconButton,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
} from '@chakra-ui/react'
import { EmptyState, InfoTip } from '@unmand-systems/components'
import assert from 'assert'
import { useEffect, useState } from 'react'
import { Control, FieldErrors, FieldValues, GlobalError, UseFormUnregister, useFieldArray } from 'react-hook-form'
import { FiPlus, FiTrash } from 'react-icons/fi'
import { BasicField, FlatData, FormField, FormFieldTypes, StyleOptions } from '../../interfaces'
// import { v4 as uuid } from 'uuid'
import { LuDatabase, LuTable } from 'react-icons/lu'
import tinycolor from 'tinycolor2'
import { EMPTY_FN } from '../../constants'
import { StyleUtils } from '../../utils'
import { FormFieldInstance } from './FormFieldInstance'
import { useConfirmationDialog } from '../../hooks'

const uuid = () => {
  return Math.random().toString(36).substring(2) + Date.now().toString(36)
}

interface TableFieldProps {
  field: FormField
  control: Control
  errors: FieldErrors
  unregister: UseFormUnregister<FieldValues>
  defaultValues: FlatData
  isDisabled?: boolean
  style: NonNullable<StyleOptions>
}

export const TableField: React.FC<TableFieldProps> = ({ field, control, errors, defaultValues, isDisabled, style }) => {
  assert(field.type === FormFieldTypes.Table)
  const { colors, inputs, layout } = StyleUtils.getTheme(style)
  const tableBorderColor = inputs.borderColor ?? 'gray.200'
  const [hasInit, setHasInit] = useState<boolean>(false)
  const key = field.key
  const { showConfirmDialog: openConfirmationDialog, RenderDialog: ConfirmationDialog } = useConfirmationDialog({
    title: 'Are you sure?',
    message: 'Are you sure you want to remove this row? This cannot be undone.',
    primaryBtnLabel: 'Remove',
    secondaryBtnLabel: 'Cancel',
  })

  const CELL_STYLING = {
    py: '2',
    bg: layout.backgroundColor,
    borderWidth: '1px',
    borderColor: tableBorderColor,
  }
  const { fields, append, remove } = useFieldArray({
    control,
    name: key,
  })

  useEffect(() => {
    if (defaultValues && !hasInit) {
      const initialRows = (defaultValues[key] ?? []) as FlatData[]
      initialRows.forEach(row => {
        append(row)
      })
      setHasInit(true)
    }
  }, [defaultValues])

  return (
    <Flex direction="column" w="100%" h="100%" overflowY="auto" pt={4}>
      <ConfirmationDialog />
      <HStack mb={2} color={colors.labelColor}>
        <LuTable />
        <Text fontWeight="500" fontSize="16px">
          {field.name}
        </Text>
        {field.helperText && <InfoTip tooltipText={field.helperText} />}

        <Button
          ml="auto"
          size="xs"
          {...StyleUtils.getGhostButtonStyle(colors.primaryColor)}
          isDisabled={isDisabled}
          colorScheme="blue"
          leftIcon={<FiPlus size="15" />}
          onClick={() => append({})}
        >
          Add Row
        </Button>
      </HStack>
      <TableContainer overflowX="auto" overflowY="auto" h="100%">
        <Table size="sm" variant="simple" bg="transparent">
          <Thead position="sticky" top="0" zIndex={10}>
            <Tr
              borderWidth="1px"
              borderColor={tableBorderColor}
              color={tinycolor(colors.labelColor).lighten(20).toString()}
              borderTop="none"
              borderBottom="none"
            >
              <Th {...CELL_STYLING} w="1%"></Th>
              {field.columns.map(column => (
                <Th key={column.columnName} {...CELL_STYLING}>
                  <Tooltip label={column.columnName} placement="top">
                    {column.displayName ? column.displayName : column.columnName}
                  </Tooltip>
                </Th>
              ))}
              <Th {...CELL_STYLING}></Th>
            </Tr>
          </Thead>
          <Tbody borderX="1px solid" borderColor={tableBorderColor}>
            {!fields?.length && (
              <Tr>
                <Td colSpan={field.columns.length + 2} w="100%" borderColor={tableBorderColor}>
                  <Center
                    p="3"
                    sx={{
                      '& > *': {
                        color: StyleUtils.getPlaceholderColor(layout.backgroundColor),
                      },
                    }}
                  >
                    <EmptyState description="This table does not have any data yet." icon={LuDatabase} />
                  </Center>
                </Td>
              </Tr>
            )}

            {fields.map((row, idx) => (
              <Tr borderWidth="1px" borderColor="gray.200" borderTop="none" borderBottom="none" key={row.id}>
                <Td {...CELL_STYLING} w="10" p="0" textAlign="center" color={colors.labelColor}>
                  {idx + 1}
                </Td>

                {field.columns.map(column => {
                  const id = uuid()
                  const field: BasicField = {
                    name: column.columnName,
                    jsonPath: column.columnName,
                    key: id,
                    isRequired: false,
                    isReadonly: false,
                    options: column?.options ?? undefined,
                    type: column.columnType as BasicField['type'],
                  }
                  const isBooleanField = [FormFieldTypes.Checkbox, FormFieldTypes.Switch].includes(
                    column.columnType as FormFieldTypes,
                  )
                  return (
                    <Td
                      key={`${row.id}-${column.columnName}`}
                      {...CELL_STYLING}
                      py={0}
                      px={0}
                      bg={colors.inputBgColor}
                      minW={
                        ![FormFieldTypes.Checkbox, FormFieldTypes.Switch].includes(column.columnType as FormFieldTypes)
                          ? '200px'
                          : '60px'
                      }
                      sx={{
                        '& .chakra-form-control': {
                          margin: isBooleanField && 'auto',
                          width: isBooleanField && 'fit-content',
                        },
                      }}
                    >
                      <FormFieldInstance
                        variant="unstyled"
                        overrideControlName={`${key}[${idx}].${column.columnName}`}
                        error={((errors[key] as any)?.[idx]?.[column.columnName] as GlobalError)?.message}
                        isDisabled={isDisabled}
                        field={field}
                        control={control}
                        errors={errors}
                        isDynamicWidth={true}
                        isLabelHidden={true}
                        hasNoOutline={true}
                        setValue={EMPTY_FN}
                        style={style}
                      />
                    </Td>
                  )
                })}
                <Td {...CELL_STYLING} w="10" p={0}>
                  <IconButton
                    borderRadius="none"
                    icon={<FiTrash />}
                    onClick={() => {
                      openConfirmationDialog(() => {
                        remove(idx)
                      })
                    }}
                    aria-label="Delete row"
                    variant="ghost"
                    colorScheme="red"
                    isDisabled={isDisabled}
                  />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Flex>
  )
}
