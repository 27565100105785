export interface FieldConditionMap {
  [fieldId: string]: Condition[]
}

export interface Condition {
  guid: string
  type: ConditionType
  source: ConditionSource[]
  target: ConditionTarget[]
  operator: ConditionOperator | null | undefined
  created: Date
  /**
   * internal properties
   */
  groupedPageId?: string
  listIndex?: number
}

export type ConditionForm = Omit<Condition, 'guid' | 'created'>

export enum ConditionType {
  SHOW_OR_HIDE = 'SHOW/HIDE',
  ENABLE_OR_REQUIRE = 'ENABLE/REQUIRE',
  SKIP_PAGE = 'SKIP-PAGE',
}

export enum ConditionOperator {
  AND = 'AND',
  OR = 'OR',
}

/**
 * Condition Source
 */
export interface ConditionSource {
  formFieldId: string
  state: ConditionSourceState
  value?: string | number | Date
}

export enum ConditionSourceState {
  IS_EMPTY = 'Is Empty',
  IS_FILLED = 'Is Filled',
  IS_EQUAL_TO = 'Is Equal To',
  IS_NOT_EQUAL_TO = 'Is Not Equal To',
  CONTAINS = 'Contains',
  DOES_NOT_CONTAIN = 'Does not Contain',
  STARTS_WITH = 'Starts With',
  DOESNT_START_WITH = "Doesn't Start With",
  ENDS_WITH = 'Ends With',
  DOESNT_END_WITH = "Doesn't End With",
  IS_TRUE = 'Is True',
  IS_FALSE = 'Is False',
  LESS_THAN = 'Less Than',
  GREATER_THAN = 'Greater Than',
  BEFORE = 'Before',
  AFTER = 'After',
  IS_EQUAL_TO_DATE = 'Is Equal to Date',
  NOT_EQUAL_TO_DATE = 'Not Equal to Date',
}

export type SourceStateValueType = 'String' | 'Number' | 'Date' | null

/**
 * Condition Target
 */

export interface ConditionTargetShowHide {
  type: ConditionType.SHOW_OR_HIDE
  targetAction: DoOptionsShowHide
  targetFieldIds: string[]
}

export enum DoOptionsShowHide {
  HIDE = 'Hide',
  SHOW = 'Show',
}

export interface ConditionTargetEnableRequire {
  type: ConditionType.ENABLE_OR_REQUIRE
  targetAction: DoOptionsEnableRequire
  targetFieldIds: string[]
}

export enum DoOptionsEnableRequire {
  REQUIRE = 'Require',
  DONT_REQUIRE = `Don't Require`,
  DISABLE = 'Disable',
  ENABLE = 'Enable',
}

export interface ConditionTargetSkipPage {
  type: ConditionType.SKIP_PAGE
  targetAction: DoOptionsSkipPage
  targetPageId: string
}

export enum DoOptionsSkipPage {
  SKIP_TO_PAGE = 'Skip to Page',
  HIDE_PAGE = `Hide Page`,
}

export type ConditionTarget = ConditionTargetEnableRequire | ConditionTargetShowHide | ConditionTargetSkipPage

export const TEMP_PAGE_ID = 'TEMP_PAGE_ID'
