import { Box, Button, Checkbox, Flex, HStack, Icon, Text, VStack, useDisclosure } from '@chakra-ui/react'
import { Loader } from '@googlemaps/js-api-loader'
import { InfoTip } from '@unmand-systems/components'
import assert from 'assert'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Control, Controller, FieldErrors, FieldValues, UseFormSetValue, useWatch } from 'react-hook-form'
import { FiX } from 'react-icons/fi'
import { LuMapPin } from 'react-icons/lu'
import tinycolor from 'tinycolor2'
import { useGoogleMaps } from '../../context'
import { useDebounce } from '../../hooks'
import { AddressFieldKeys, FormField, FormFieldTypes, PredictionResponse, StyleOptions } from '../../interfaces'
import { StyleUtils } from '../../utils'
import { InputField } from './InputField'

interface AddressFieldProps {
  field: FormField
  control: Control
  errors: FieldErrors
  isDisabled?: boolean
  setValue: UseFormSetValue<FieldValues>
  style: NonNullable<StyleOptions>
}
const MINIMUM_SEARCH_LENGTH = 3

export const AddressInputField: React.FC<AddressFieldProps> = props => {
  const { google } = useGoogleMaps()

  if (!google) {
    return <></>
  }

  return <AddressInput {...props} />
}

export const AddressInput: React.FC<AddressFieldProps> = ({ field, control, isDisabled, errors, style, setValue }) => {
  assert(field.type === FormFieldTypes.Address)
  const { google } = useGoogleMaps()
  const [searchResult, setSearchResult] = useState<any>([])
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [search, setSearch] = useState('')

  const googlemap = useRef(null)

  const autocompleteService = new google.maps.places.AutocompleteService()
  const placesService = new google.maps.places.PlacesService(document.createElement('div'))
  const sessionToken = useMemo(
    () => new google.maps.places.AutocompleteSessionToken(),
    [google.maps.places.AutocompleteSessionToken],
  )

  const { colors } = StyleUtils.getTheme(style)
  const isManuallyEntered = useWatch({
    control,
    name: `${field.key}-${AddressFieldKeys.IS_MANUALLY_ENTERED}`,
    defaultValue: false,
  })

  const debouncedSearchChange = useDebounce(() => {
    onSearchAddress(search)
  })

  const onSearchAddress = (searchString = '') => {
    setSearch(searchString)

    if (searchString.length < MINIMUM_SEARCH_LENGTH) {
      setSearchResult([])
    } else {
      let restrictions = {}

      if (field.countryRestrictions?.length) {
        restrictions = {
          componentRestrictions: {
            country: field.countryRestrictions,
          },
        }
      }

      autocompleteService.getPlacePredictions(
        {
          input: searchString ?? '',
          sessionToken: sessionToken,
          ...restrictions,
        },

        handlePredictions,
      )
    }
  }

  const onSelectPlace = (placeId: string) => {
    placesService.getDetails(
      {
        placeId: placeId,
        fields: ['address_component', 'formatted_address'],
      },
      (place, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          const fullAddress = place.formatted_address
          const addressComponents = place.address_components
          const subpremise = addressComponents.find(component => component.types.includes('subpremise'))
          const streetNumber = addressComponents.find(component => component.types.includes('street_number'))
          const route = addressComponents.find(component => component.types.includes('route'))
          const locality = addressComponents.find(component => component.types.includes('locality'))
          const administrativeArea = addressComponents.find(component =>
            component.types.includes('administrative_area_level_1'),
          )
          const country = addressComponents.find(component => component.types.includes('country'))
          const postalCode = addressComponents.find(component => component.types.includes('postal_code'))
          const sublocality = addressComponents.find(component => component.types.includes('sublocality_level_1'))

          const streetAddressLine1 =
            (subpremise?.long_name ? `${subpremise?.long_name}/` : '') +
            (streetNumber ? streetNumber.long_name + ' ' : '') +
            (route ? route.long_name : '')
          const streetAddressLine2 = sublocality ? sublocality.long_name : ''

          setValue(`${field.key}`, fullAddress)
          setValue(`${field.key}-${AddressFieldKeys.STREET_ADDRESS}`, streetAddressLine1)
          setValue(`${field.key}-${AddressFieldKeys.STREET_ADDRESS_2}`, streetAddressLine2)
          setValue(`${field.key}-${AddressFieldKeys.CITY}`, locality ? locality.long_name : '')
          setValue(
            `${field.key}-${AddressFieldKeys.STATE_OR_PROVINCE}`,
            administrativeArea ? administrativeArea.long_name : '',
          )
          setValue(`${field.key}-${AddressFieldKeys.COUNTRY}`, country ? country.long_name : '')
          setValue(`${field.key}-${AddressFieldKeys.POSTAL_ZIP_CODE}`, postalCode ? postalCode.long_name : '')
        } else {
          console.error('Error fetching place details:', status)
        }
      },
    )
  }

  const handlePredictions = (predictions: PredictionResponse[], status: 'OK' | string) => {
    if (status === 'OK') {
      const autocompleteSuggestions = predictions
        .map(prediction => {
          if (!prediction?.structured_formatting) {
            return null
          }

          return {
            id: prediction.place_id,
            name: {
              value: prediction.structured_formatting?.main_text,
              length: prediction.structured_formatting?.main_text_matched_substrings?.[0]['length'],
              offset: prediction.structured_formatting?.main_text_matched_substrings?.[0]['offset'],
            },
            address: {
              value: prediction.structured_formatting?.secondary_text,
              length: prediction.structured_formatting?.secondary_text_matched_substrings?.[0]['length'],
              offset: prediction.structured_formatting?.secondary_text_matched_substrings?.[0]['offset'],
            },
          }
        })
        .filter(prediction => prediction !== null)

      setSearchResult(autocompleteSuggestions)
    } else {
      setSearchResult([])
    }
  }

  const boldMatchedSearch = ({ length, offset, value }: { length: number; offset: number; value: string }) => {
    if ((length === 0 && offset === 0) || !value) {
      return value
    }
    const userText = value.substring(offset, offset + length)
    const stringBefore = value.substring(0, offset)
    const stringAfter = value.substring(offset + length)
    return `${stringBefore}<b>${userText}</b>${stringAfter}`
  }

  useEffect(() => {
    const loader = new Loader({
      apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string,
      version: 'weekly',
      libraries: ['places'],
    })

    loader.load()
  }, [])

  return (
    <VStack w="100%" gap="4" alignItems="flex-start">
      <div id="map" ref={googlemap} />
      <Flex w="100%" alignItems="center" gap="2">
        <Text fontWeight="500">{field.name}</Text>
        <span className="form__optional-indicator">
          {!field.isRequired && style?.inputs?.hideRequiredAsterisk && '- Optional'}
        </span>
        {field.helperText && <InfoTip tooltipText={field.helperText} />}
      </Flex>
      <Box
        sx={{
          display: 'contents',
          '& .form__optional-indicator': {
            display: 'none !important',
          },
        }}
      >
        {!isManuallyEntered && (
          <InputField
            variant="outline"
            type={FormFieldTypes.PlainTextInput}
            isDisabled={isDisabled}
            label={'Address'}
            formControlName={`${field.key}`}
            control={control}
            placeholder={'Search'}
            error={errors[field.key]?.message as string}
            hideErrorMsg={true}
            isReadonly={field.isReadonly}
            onChange={(searchString: string | null) => {
              setSearch(searchString ?? '')
              debouncedSearchChange()
            }}
            onFocus={onOpen}
            style={style}
          />
        )}
        {isOpen && searchResult?.length ? (
          <VStack
            bg="white"
            boxShadow="md"
            p={0}
            mt={2}
            borderRadius="md"
            position="absolute"
            zIndex="popover"
            width="100%"
            gap="0"
            top="100px"
            alignItems="flex-start"
          >
            <Flex px="2" pt="2">
              <Button onClick={onClose} variant="ghost" colorScheme="gray" leftIcon={<Icon as={FiX} />}>
                Close search results
              </Button>
            </Flex>

            {searchResult.map(placeOption => (
              <Flex
                key={placeOption.id}
                gap="2"
                px="4"
                py="2"
                alignItems="center"
                justifyContent="flex-start"
                cursor="pointer"
                _hover={{ bg: 'gray.50' }}
                w="100%"
                onClick={() => {
                  onSelectPlace(placeOption.id)
                  onClose()
                }}
                color="gray.900"
              >
                <Icon as={LuMapPin} size="sm" />
                <VStack gap="0" alignItems="start">
                  <Text fontSize="sm" dangerouslySetInnerHTML={{ __html: boldMatchedSearch(placeOption.name) }}></Text>
                  <Text
                    fontSize="xs"
                    dangerouslySetInnerHTML={{ __html: boldMatchedSearch(placeOption.address) }}
                  ></Text>
                </VStack>
              </Flex>
            ))}
          </VStack>
        ) : (
          <></>
        )}

        <Controller
          name={`${field.key}-${AddressFieldKeys.IS_MANUALLY_ENTERED}`}
          control={control}
          render={({ field: controlField }) => (
            <Flex alignItems="center" gap="2" mb="2">
              <Checkbox
                isChecked={controlField.value}
                onChange={newValue => {
                  controlField.onChange(newValue)
                }}
                sx={{
                  'span.chakra-switch__track[data-checked]': { backgroundColor: colors.primaryColor },
                  'span.chakra-checkbox__control[data-checked]': {
                    backgroundColor: colors.primaryColor,
                    borderColor: colors.primaryColor,
                    '&:hover': {
                      backgroundColor: colors.primaryColor
                        ? tinycolor(colors.primaryColor).darken().toString()
                        : 'blue.600',
                      borderColor: colors.primaryColor
                        ? tinycolor(colors.primaryColor).darken().toString()
                        : 'blue.600',
                    },
                  },
                }}
              >
                Can&apos;t find your address? Enter it manually.
              </Checkbox>
            </Flex>
          )}
        />

        <InputField
          variant="outline"
          type={FormFieldTypes.PlainTextInput}
          isDisabled={isDisabled}
          label={'Street Address'}
          formControlName={`${field.key}-${AddressFieldKeys.STREET_ADDRESS}`}
          error={errors[`${field.key}-${AddressFieldKeys.STREET_ADDRESS}`]?.message as string}
          control={control}
          placeholder={'Street Address'}
          isRequired={field.isRequired}
          isReadonly={field.isReadonly}
          style={style}
          hideErrorMsg={true}
        />
        <InputField
          variant="outline"
          type={FormFieldTypes.PlainTextInput}
          isDisabled={isDisabled}
          label={'Street Address Line 2'}
          formControlName={`${field.key}-${AddressFieldKeys.STREET_ADDRESS_2}`}
          control={control}
          placeholder={'Street Address Line 2'}
          isReadonly={field.isReadonly}
          style={style}
        />
        <HStack gap="4" w="100%">
          <InputField
            variant="outline"
            type={FormFieldTypes.PlainTextInput}
            isDisabled={isDisabled}
            label={'City'}
            formControlName={`${field.key}-${AddressFieldKeys.CITY}`}
            error={errors[`${field.key}-${AddressFieldKeys.CITY}`]?.message as string}
            control={control}
            placeholder={'City'}
            isRequired={field.isRequired}
            isReadonly={field.isReadonly}
            style={style}
            hideErrorMsg={true}
          />
          <InputField
            variant="outline"
            type={FormFieldTypes.PlainTextInput}
            isDisabled={isDisabled}
            label={'State'}
            formControlName={`${field.key}-${AddressFieldKeys.STATE_OR_PROVINCE}`}
            error={errors[`${field.key}-${AddressFieldKeys.STATE_OR_PROVINCE}`]?.message as string}
            control={control}
            placeholder={'State'}
            isRequired={field.isRequired}
            isReadonly={field.isReadonly}
            style={style}
            hideErrorMsg={true}
          />
        </HStack>
        <HStack gap="4" w="100%">
          <InputField
            variant="outline"
            type={FormFieldTypes.PlainTextInput}
            isDisabled={isDisabled}
            label={'Country'}
            formControlName={`${field.key}-${AddressFieldKeys.COUNTRY}`}
            error={errors[`${field.key}-${AddressFieldKeys.COUNTRY}`]?.message as string}
            control={control}
            placeholder={'Country'}
            isRequired={field.isRequired}
            isReadonly={field.isReadonly}
            style={style}
            hideErrorMsg={true}
          />
          <InputField
            variant="outline"
            type={FormFieldTypes.PlainTextInput}
            isDisabled={isDisabled}
            label={'Postal Code'}
            formControlName={`${field.key}-${AddressFieldKeys.POSTAL_ZIP_CODE}`}
            error={errors[`${field.key}-${AddressFieldKeys.POSTAL_ZIP_CODE}`]?.message as string}
            control={control}
            placeholder={'Postal Code'}
            isRequired={field.isRequired}
            isReadonly={field.isReadonly}
            style={style}
            hideErrorMsg={true}
          />
        </HStack>
      </Box>
    </VStack>
  )
}
