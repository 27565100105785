import { FlatData, FormResponse } from '../interfaces'
import { callApi } from './call-api'

const FORMS_API_URL = process.env.REACT_APP_API_URL

const getFormPreview = (formId: string, authToken: string, password?: string): Promise<FormResponse> => {
  return callApi({
    baseUrl: `${FORMS_API_URL}/forms/${formId}/preview`,
    request: {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
        ...(password && { 'X-FORM-PASSWORD': password }),
      },
    },
  })
}

const getFormPrivate = (formId: string, authToken: string, password?: string): Promise<FormResponse> => {
  return callApi({
    baseUrl: `${FORMS_API_URL}/forms/${formId}/private`,
    request: {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
        ...(password && { 'X-FORM-PASSWORD': password }),
      },
    },
  })
}

const getFormPublic = (formToken: string, password?: string): Promise<FormResponse> => {
  return callApi({
    baseUrl: `${FORMS_API_URL}/forms/public/${formToken}`,
    request: {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        ...(password && { 'X-FORM-PASSWORD': password }),
      },
    },
  })
}

const submitFormPrivate = async ({
  formId,
  formData,
  authToken,
  password,
  prefill,
  htmlFile,
}: {
  formId: string
  formData: FlatData
  authToken: string
  password?: string
  prefill?: string
  htmlFile?: File
}): Promise<string> => {
  const formDataRequest = new FormData()
  formDataRequest.append(
    'submission',
    JSON.stringify(formData, (_, value) => {
      return value === undefined ? null : value
    }),
  )

  if (htmlFile) {
    formDataRequest.append('submitted_data_html_file', htmlFile)
  }

  const response = await callApi({
    baseUrl: `${FORMS_API_URL}/forms/${formId}/private`,
    parseResponse: true,
    request: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
        ...(password && { 'X-FORM-PASSWORD': password }),
      },
      body: JSON.stringify({ form: formData }),
    },
    params: prefill ? { prefill } : {},
  })

  if (response) {
    const blobUrl = URL.createObjectURL(response as Blob)
    return blobUrl
  } else {
    throw new Error('Failed to submit form')
  }
}

const submitFormPublic = async ({
  formToken,
  formData,
  password,
  prefill,
  htmlFile,
}: {
  formToken: string
  formData: FlatData
  password?: string
  prefill?: string
  htmlFile?: File
}): Promise<string> => {
  const formDataRequest = new FormData()
  formDataRequest.append(
    'submission',
    JSON.stringify(formData, (_, value) => {
      return value === undefined ? null : value
    }),
  )

  if (htmlFile) {
    formDataRequest.append('submitted_data_html_file', htmlFile)
  }

  const response = await callApi({
    baseUrl: `${FORMS_API_URL}/forms/public/${formToken}`,
    parseResponse: true,
    request: {
      method: 'POST',
      headers: {
        ...(password && { 'X-FORM-PASSWORD': password }),
      },
      body: formDataRequest,
    },
    params: prefill ? { prefill } : {},
  })

  if (response) {
    const blobUrl = URL.createObjectURL(response as Blob)
    return blobUrl
  } else {
    throw new Error('Failed to submit form')
  }
}

const getPrefilledData = (prefillId: string): Promise<{ [key: string]: any }> => {
  return callApi({
    baseUrl: `${FORMS_API_URL}/forms/public/prefills/${prefillId}`,
    request: {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  })
}

const logCrash = async (errorDetails: any): Promise<void> => {
  return callApi({
    baseUrl: `${FORMS_API_URL}/log-crash`,
    request: {
      method: 'POST',
      body: JSON.stringify(errorDetails),
      headers: {
        'Content-Type': 'application/json',
      },
    },
  })
}

export const FormsApi = {
  getFormPreview,
  getFormPrivate,
  getFormPublic,
  submitFormPublic,
  submitFormPrivate,
  getPrefilledData,
  logCrash,
}
