import { Layout } from 'react-grid-layout'
import { Condition } from './condition'
import { FormFieldTypes } from './form-field'
import { Page } from './page'
import { Style } from './styles'

export interface FormResponse {
  form: FormDetails
  layout: APIFormFieldPages
  style: Style
  conditions: Condition[]
  pages: Page[]
}

export interface FormDetails {
  logoUrl: string
  faviconUrl: string
  name: string
  readOnly: boolean
  captchaEnabled: boolean
  completedMessageTitle: string | null
  completedMessageBody: string | null
  defaultPayload?: string | null
  progressComponentType?: ProgressComponentType | null
  showStepName: boolean
  attachHtmlFile?: boolean
  submissionDownloadEnabled: boolean
}

export interface APIFormField {
  guid?: string
  name?: string
  jsonpath?: string
  component: FormFieldTypes
  config: any
  layout: Layout
  listIndex?: number
}

export interface APIFormFieldPages {
  [page: Page['guid']]: APIFormField[]
}

export enum ProgressComponentType {
  STEPPER = 'Stepper',
  PROGRESS_BAR = 'Progress Bar',
}
