import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputGroupProps,
  InputLeftElement,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
  theme,
  useColorModeValue,
  useDisclosure,
  useOutsideClick,
} from '@chakra-ui/react'
import { DateRangePicker, InfoTip } from '@unmand-systems/components'
import assert from 'assert'
import { format, isValid, parse } from 'date-fns'
import { useRef, useState } from 'react'
import ReactDatePicker from 'react-datepicker'
import { Control, Controller } from 'react-hook-form'
import { LuCalendar } from 'react-icons/lu'
import { PatternFormat } from 'react-number-format'
import tinycolor from 'tinycolor2'
import { useFormAccessibility } from '../../context/FormAccessibilityContext'
import { FormField, FormFieldTypes, StyleOptions } from '../../interfaces'
import { DatetimeUtils, StyleUtils } from '../../utils'
import { DatePickerHeader } from '../core/DatepickerHeader'

interface TypeInputProps {
  label: string
  fieldType: FormField['type']
  formControlName: string
  control: Control
  labelTooltip?: string
  error?: string
  minDate?: Date
  maxDate?: Date
  futureDatesEnabled: boolean
  pastDatesEnabled: boolean
  helperText?: string
  placeholder?: string
  isRequired?: boolean
  isLabelHidden?: boolean
  isDisabled?: boolean
  isReadonly?: boolean
  isDynamicWidth?: boolean
  hasNoOutline?: boolean
  overrideControlName?: string
  variant?: InputGroupProps['variant']
  style: NonNullable<StyleOptions>
}

const START_OF_DAY = 'T00:00:00'

export const DatepickerField: React.FC<TypeInputProps> = ({
  variant = 'outlined',
  fieldType,
  label,
  formControlName,
  control,
  error,
  isRequired,
  isLabelHidden,
  isDisabled,
  isReadonly,
  minDate,
  maxDate,
  futureDatesEnabled,
  pastDatesEnabled,
  helperText,
  placeholder,
  overrideControlName,
  style,
}) => {
  assert(fieldType === FormFieldTypes.Datepicker || fieldType === FormFieldTypes.DateRangePicker)

  const { colors, inputs } = StyleUtils.getTheme(style)

  const bg = useColorModeValue('white', 'gray.800')
  const border = useColorModeValue(`1px solid ${theme.colors.gray[200]}`, 'none')
  const disabledBg = useColorModeValue('white', `${theme.colors.gray[800]} !important`)
  const placeholderColor = StyleUtils.getPlaceholderColor(colors.inputBgColor)
  const iconColor = StyleUtils.getIconColor(colors.inputBgColor)
  const [displayedValue, setDisplayedValue] = useState<string | null | undefined>()
  const [lastSavedValue, setLastSavedValue] = useState<string | null>(null)
  const { isOpen, onToggle, onClose } = useDisclosure()
  const popoverRef = useRef(null)
  useOutsideClick({
    ref: popoverRef,
    handler: onClose,
  })
  const { getTabIndex } = useFormAccessibility()

  const fieldSx = {
    flexDir: 'column',
    justifyContent: 'center',
    height: '100%',
    display: 'flex',
    '& .chakra-input__left-element, .chakra-button': {
      color: placeholderColor,
      '&:hover': {
        bg: 'none',
      },
    },
    '& .react-datepicker__input-container': {
      background: bg,
      border,
      '& > input:disabled': {
        background: disabledBg,
      },
      '& ::placeholder': {
        color: placeholderColor,
        fontStyle: 'italic',
      },
    },
  }

  const sx = {
    borderWidth: variant === 'outline' ? inputs.borderWidth : 0,
    borderRadius: variant !== 'unstyled' ? inputs.borderRadius : 0,
    borderColor: variant === 'outline' ? inputs.borderColor : 'none',
    bg: colors.inputBgColor,
    color: colors.inputTextColor,
    _hover: { bg: colors.inputBgColor },
    _focusVisible: {
      borderColor: colors.primaryColor,
      boxShadow: `0 0 0 1px ${colors.primaryColor}`,
    },
    _focus: {
      borderColor: colors.primaryColor,
    },
    '::placeholder': {
      color: placeholderColor,
      fontStyle: 'italic',
    },
  }

  const datepickerSx = {
    '& .react-datepicker__day--in-range': {
      bg: `${tinycolor(colors.primaryColor).setAlpha(0.15).toString()} !important`,
    },
    '& .react-datepicker__day--selected, .react-datepicker__day--range-start, .react-datepicker__day--range-end': {
      bg: `${colors.primaryColor} !important`,
      color: tinycolor(colors.primaryColor).isDark() ? 'white !important' : 'black !imporant',
    },
  }

  return (
    <Controller
      name={overrideControlName ?? formControlName}
      control={control}
      render={({ field }) => {
        if (
          fieldType === FormFieldTypes.Datepicker &&
          (displayedValue === undefined || lastSavedValue !== field.value)
        ) {
          setDisplayedValue(field.value ? format(new Date(field.value), 'dd/MM/yyyy') : null)
        }

        const handleRawDateChange = (dateString: string) => {
          const newRawDate = parse(dateString, 'dd/MM/yyyy', new Date())

          if (DatetimeUtils.isValidDateFormat(dateString) && newRawDate instanceof Date && !isNaN(newRawDate as any)) {
            let newValue = format(newRawDate, 'yyyy-MM-dd')
            newValue += START_OF_DAY

            setLastSavedValue(newValue)
            field.onChange(newValue)
          } else {
            setLastSavedValue(null)
            field.onChange(null)
          }
        }

        return (
          <FormControl
            isInvalid={!!error}
            isRequired={isRequired}
            flexDir="column"
            justifyContent="center"
            height={'100%'}
            display="flex"
            sx={fieldSx}
          >
            {!isLabelHidden && (
              <FormLabel display="flex" alignItems="center" gap="1" color={colors?.labelColor}>
                {label}
                <span className="form__optional-indicator">
                  {!isRequired && inputs.hideRequiredAsterisk && '- Optional'}
                </span>
                {helperText && <InfoTip tooltipText={helperText} />}
              </FormLabel>
            )}
            {fieldType === FormFieldTypes.Datepicker ? (
              <Popover autoFocus={false} placement="bottom-start" isOpen={isOpen && !isDisabled}>
                <PopoverTrigger>
                  <InputGroup onClick={onToggle}>
                    <InputLeftElement pointerEvents="none">
                      <LuCalendar color={iconColor} />
                    </InputLeftElement>
                    <Input
                      tabIndex={getTabIndex(formControlName)}
                      value={displayedValue ?? ''}
                      variant={variant}
                      isInvalid={
                        !!error ||
                        (!!displayedValue &&
                          (!DatetimeUtils.isValidDateFormat(displayedValue) ||
                            !isValid(parse(displayedValue, 'dd/MM/yyyy', new Date()))))
                      }
                      placeholder={isReadonly ? 'Not Available' : placeholder || 'DD/MM/YYYY'}
                      onChange={e => {
                        const newVal = e.target.value
                        setDisplayedValue(newVal)
                        handleRawDateChange(newVal)
                      }}
                      isDisabled={isDisabled}
                      sx={sx}
                      {...{
                        as: PatternFormat,
                        format: '##/##/####',
                        mask: '_',
                        getInputRef: field.ref,
                      }}
                    />
                  </InputGroup>
                </PopoverTrigger>
                <Portal>
                  <Box ref={popoverRef}>
                    <PopoverContent bg="none" boxShadow="none" border="none" borderRadius="none" width="fit-content">
                      <Flex
                        w="fit-content"
                        bg="chakra-body-bg"
                        borderRadius="md"
                        border="1px solid"
                        borderColor="chakra-border-color"
                        sx={{
                          '& > div:last-child': {
                            display: 'flex',
                          },
                          '& .react-datepicker__day:hover': {
                            borderRadius: '4px !important',
                            border: 'none',
                          },
                          '& .react-datepicker__day--selected': {
                            bg: `${colors.primaryColor} !important`,
                            color: tinycolor(colors.primaryColor).isDark() ? 'white !important' : 'black !imporant',
                          },
                        }}
                      >
                        <ReactDatePicker
                          {...field}
                          inline
                          todayButton="Today"
                          showPopperArrow={false}
                          selected={field.value ? new Date(field.value) : null}
                          renderCustomHeader={props => <DatePickerHeader {...props} />}
                          disabled={isDisabled}
                          minDate={minDate ? minDate : pastDatesEnabled === false ? new Date() : new Date(1900, 1, 1)}
                          maxDate={
                            maxDate ? maxDate : futureDatesEnabled === false ? new Date() : new Date(2100, 12, 31)
                          }
                          readOnly={isDisabled}
                          onChange={date => {
                            let newValue = date ? format(date, 'yyyy-MM-dd') : null

                            if (newValue) {
                              newValue += START_OF_DAY
                            }

                            setLastSavedValue(newValue)
                            setDisplayedValue(date ? format(date, 'dd/MM/yyyy') : null)
                            field.onChange(newValue)
                            onClose()
                          }}
                          dateFormat="yyyy-MM-dd"
                          ref={() => field.ref}
                        />
                      </Flex>
                    </PopoverContent>
                  </Box>
                </Portal>
              </Popover>
            ) : (
              <DateRangePicker
                startDate={field.value?.[0] ? new Date(field.value[0]) : null}
                endDate={field.value?.[1] ? new Date(field.value[1]) : null}
                size="md"
                shortcuts={false}
                dateFormat="dd/MM/yyyy"
                handleChange={dates => field.onChange(dates)}
                isClearable
                width="100%"
                sx={sx}
                minDate={minDate ?? new Date(1900, 1, 1)}
                maxDate={maxDate ?? new Date(2100, 12, 31)}
                datepickerSx={datepickerSx}
                isDisabled={isDisabled || isReadonly}
                placeholder={placeholder || 'Select dates'}
                showIcon
              />
            )}
            {error && <FormErrorMessage>{error}</FormErrorMessage>}
          </FormControl>
        )
      }}
    />
  )
}
