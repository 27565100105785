import {
  Box,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  Radio,
  Switch,
  Text,
  Tooltip,
  useCheckbox,
  useCheckboxGroup,
  useRadio,
  useRadioGroup,
} from '@chakra-ui/react'
import { InfoTip } from '@unmand-systems/components'
import assert from 'assert'
import { Control, Controller, ControllerRenderProps, FieldValues } from 'react-hook-form'
import tinycolor from 'tinycolor2'
import { DEFAULT_THEME } from '../../constants'
import { useFormAccessibility } from '../../context/FormAccessibilityContext'
import { FormField, FormFieldTypes, IconOption, StyleOptions } from '../../interfaces'
import { StyleUtils } from '../../utils'
import { DynamicIcon } from '../core/DynamicIcon'
import { useFormState } from '../../context/FormStateContext'

interface IconPickerFieldProps {
  field: FormField
  control: Control
  error: string
  isDisabled?: boolean
  overrideControlName?: string
  style: NonNullable<StyleOptions>
  multiselect: boolean
  iconBoxSize: number
}

export const IconPickerField = (props: IconPickerFieldProps) => {
  assert(props.field.type === FormFieldTypes.IconPicker)

  const { isPrintMode } = useFormState()

  const { field, control, error, isDisabled, overrideControlName, style: sx, multiselect = false } = props

  const style = StyleUtils.getTheme(sx)

  const { colors } = style
  const iconBoxSize = isPrintMode ? 12 : props.iconBoxSize ?? 8
  const { getTabIndex } = useFormAccessibility()

  return (
    <Controller
      name={overrideControlName ?? field.key}
      defaultValue={multiselect ? [] : undefined}
      control={control}
      render={({ field: controlField }) => (
        <FormControl isRequired={field.isRequired} isInvalid={!!error}>
          <Flex w="100%" justify="space-between" mb={2}>
            <FormLabel display="flex" alignItems="center" gap="1" color={colors?.labelColor}>
              {field.name}
              <span className="form__optional-indicator">
                {!field.isRequired && style.inputs.hideRequiredAsterisk && '- Optional'}
              </span>
              {field.helperText && <InfoTip tooltipText={field.helperText} />}
            </FormLabel>

            {multiselect && (
              <FormControl display="flex" gap={2} alignItems="center" w="fit-content" className="hide-on-print">
                <Switch
                  isDisabled={field.isReadonly}
                  tabIndex={getTabIndex(controlField.name)}
                  size="sm"
                  isChecked={controlField.value?.length === field.icons?.length}
                  onChange={() =>
                    controlField.onChange(
                      controlField.value.length === field.icons?.length ? [] : field.icons.map(i => i.label),
                    )
                  }
                  _checked={{
                    '& .chakra-switch__track': {
                      bg: colors.primaryColor,
                    },
                  }}
                />
                <FormLabel m={0}>Select all</FormLabel>
              </FormControl>
            )}
          </Flex>

          {multiselect ? (
            <CheckboxPicker
              {...props}
              field={controlField}
              isDisabled={Boolean(isDisabled || field.isReadonly)}
              style={style}
              icons={field.icons}
              iconBoxSize={iconBoxSize}
              hideLabels={field.hideLabels}
              cellsPerRow={field.cellsPerRow}
            />
          ) : (
            <RadioPicker
              field={controlField}
              name={overrideControlName ?? field.key}
              isDisabled={Boolean(isDisabled || field.isReadonly)}
              style={style}
              icons={field.icons}
              iconBoxSize={iconBoxSize}
              hideLabels={field.hideLabels}
              cellsPerRow={field.cellsPerRow}
            />
          )}
          {error && <FormErrorMessage>{error}</FormErrorMessage>}
        </FormControl>
      )}
    />
  )
}

interface PickerProps {
  field: ControllerRenderProps<FieldValues, string>
  isDisabled: boolean
  name?: string
  style: typeof DEFAULT_THEME
  icons: IconOption[]
  iconBoxSize: number
  hideLabels: boolean
  cellsPerRow: number
}

const RadioPicker = (props: PickerProps) => {
  const { field, isDisabled, name, style, icons, iconBoxSize, hideLabels, cellsPerRow } = props

  const { getRootProps, getRadioProps } = useRadioGroup({ name })

  const group = getRootProps()
  const { getTabIndex } = useFormAccessibility()

  return (
    <Grid gridTemplateColumns={`repeat(${cellsPerRow}, 1fr)`} gap={4} {...group}>
      {icons?.length ? (
        icons?.map(icon => {
          const radio = getRadioProps({ value: icon.label })
          return (
            <IconRadioCard
              key={icon.label}
              {...radio}
              {...icon}
              tabIndex={getTabIndex(field.name)}
              style={style}
              isChecked={field.value === icon.label}
              onChange={() => field.onChange(icon.label)}
              isDisabled={isDisabled}
              iconBoxSize={icon.boxSize ?? iconBoxSize}
              hideLabels={hideLabels}
            />
          )
        })
      ) : (
        <IconRadioCard
          key={0}
          icon="LuPlus"
          label="Example icon"
          style={style}
          isDisabled={isDisabled}
          iconBoxSize={iconBoxSize}
        />
      )}
    </Grid>
  )
}

interface CardProps {
  icon: string
  label?: string
  style: typeof DEFAULT_THEME
  isDisabled: boolean
  isChecked?: boolean
  iconBoxSize: number
  onChange?: (v: any) => void
  hideLabels?: boolean
  helperText?: string
  tabIndex?: number
}

const IconRadioCard = ({ icon, iconBoxSize, label, style, hideLabels, helperText, tabIndex, ...props }: CardProps) => {
  const { getInputProps, getRadioProps } = useRadio(props)

  const { isDisabled, isChecked } = props

  const input = getInputProps()
  const checkbox = getRadioProps()

  return (
    <Tooltip label={helperText} placement="top">
      <Box as="label">
        <input {...input} tabIndex={tabIndex} />
        <Box {...checkbox} sx={cardStyle(style)}>
          <DynamicIcon icon={icon} boxSize={iconBoxSize} />
          {!hideLabels && (
            <Text fontSize="sm" my={2} fontWeight="medium" wordBreak="break-all">
              {label}
            </Text>
          )}
          <Radio isChecked={isChecked} isDisabled={isDisabled} mt="auto" />
        </Box>
      </Box>
    </Tooltip>
  )
}

const CheckboxPicker = (props: PickerProps) => {
  const { field, isDisabled, style, icons, iconBoxSize, hideLabels, cellsPerRow } = props

  const { getCheckboxProps } = useCheckboxGroup()
  const { getTabIndex } = useFormAccessibility()

  const handleChange =
    (optionLabel: string): React.ChangeEventHandler<HTMLInputElement> =>
    e => {
      const value = Array.isArray(field?.value) ? field?.value : []

      field.onChange(e.target.checked ? [...value, optionLabel] : value?.filter((v: string) => v !== optionLabel))
    }

  return (
    <Grid gridTemplateColumns={`repeat(${cellsPerRow}, 1fr)`} gap={4}>
      {icons?.length ? (
        icons?.map(icon => {
          return (
            <IconCheckboxCard
              key={icon.label}
              {...getCheckboxProps({ value: icon.label })}
              {...icon}
              tabIndex={getTabIndex(field.name)}
              style={style}
              isChecked={field.value?.includes(icon.label)}
              onChange={handleChange(icon.label)}
              isDisabled={isDisabled}
              iconBoxSize={icon.boxSize ?? iconBoxSize}
              hideLabels={hideLabels}
            />
          )
        })
      ) : (
        <IconCheckboxCard
          key={0}
          icon="LuPlus"
          label="Example icon"
          style={style}
          isDisabled={isDisabled}
          iconBoxSize={iconBoxSize}
        />
      )}
    </Grid>
  )
}

const IconCheckboxCard = ({
  icon,
  label,
  style,
  helperText,
  iconBoxSize,
  hideLabels,
  tabIndex,
  ...props
}: CardProps) => {
  const { state, getCheckboxProps, getInputProps, getLabelProps, htmlProps } = useCheckbox(props)

  const { isDisabled } = props

  return (
    <Tooltip label={helperText} placement="top">
      <Box as="label" {...htmlProps} w="100%" h="100%">
        <input {...getInputProps()} tabIndex={tabIndex} />
        <Box {...getCheckboxProps()} sx={cardStyle(style)}>
          <DynamicIcon icon={icon} boxSize={iconBoxSize} />
          {!hideLabels && (
            <Text fontSize="sm" my={2} fontWeight="medium" wordBreak="break-all" noOfLines={3} {...getLabelProps}>
              {label}
            </Text>
          )}
          <Checkbox isChecked={state.isChecked} isDisabled={isDisabled} mt="auto" />
        </Box>
      </Box>
    </Tooltip>
  )
}

const cardStyle = (style: typeof DEFAULT_THEME) => {
  const { layout, colors } = style
  return {
    display: 'flex',
    flexDirection: 'column',
    py: 4,
    px: 4,
    h: '100%',
    alignItems: 'center',
    cursor: 'pointer',
    borderWidth: '1px',
    borderRadius: 'md',
    transition: 'all 0.2s ease-in-out',
    borderColor: tinycolor(layout.backgroundColor).isLight() ? 'blackAlpha.300' : 'whiteAlpha.300',
    _checked: {
      borderColor: colors.primaryColor,
      boxShadow: `0 0 0 0px #fff, 0 0 0 2px ${colors.primaryColor}`,
    },
    _focus: {
      boxShadow: `0 0 0 3px ${tinycolor(colors.primaryColor).setAlpha(0.6).toString()}`,
    },
    _hover: {
      bg: tinycolor(layout.backgroundColor).isLight() ? 'blackAlpha.50' : 'whiteAlpha.50',
    },
    '& [data-checked]': {
      borderColor: colors.primaryColor,
      backgroundColor: colors.primaryColor,
      borderRadius: 'full',
      '&.chakra-checkbox__control': {
        borderRadius: 'base',
      },
      _hover: {
        borderColor: colors.primaryColor,
        background: colors.primaryColor,
      },
    },
  }
}
