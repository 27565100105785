import { format, isAfter, isBefore, startOfDay } from 'date-fns'
import { isArray, isNil } from 'lodash'
import { z } from 'zod'
import { BasicField, FormField, FormFieldTypes } from '../interfaces'
import { DatetimeUtils } from './datetime.utils'
import { FormBuilderUtils } from './form-builder.utils'

export type ValidationSchema = Record<string, z.ZodType>

// Based on https://ihateregex.io/expr/phone/
export const phoneNumberRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/

// Based on https://regexr.com/3e48o
export const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i

export const alphanumericSpacesUnderscoresDashes = /^[A-Za-z0-9_-\s]*$/

// Custom validation function to check if items in an array are unique
export const uniqueArrayValidator = (values: string[]) => {
  const set = new Set(values)
  return set.size === values.length
}

const initializeValidationSchema = (fields: FormField[]): ValidationSchema => {
  const validationSchema: ValidationSchema = {}

  fields.forEach(field => {
    if (
      field.type === FormFieldTypes.Header ||
      field.type === FormFieldTypes.Divider ||
      field.type === FormFieldTypes.StaticMarkdown
    ) {
      return
    }

    const validation: z.ZodTypeAny | undefined = generateValidation(field)

    if (!validation) {
      return
    }

    if (field.type === FormFieldTypes.Address) {
      FormBuilderUtils.getRequiredAddressKeys(field.key).forEach(key => {
        validationSchema[key] = validation
      })
    } else {
      validationSchema[field.key] = validation
    }
  })

  return validationSchema
}

const generateValidation = (field: FormField) => {
  let validation

  if (
    field.type === FormFieldTypes.Header ||
    field.type === FormFieldTypes.Divider ||
    field.type === FormFieldTypes.StaticMarkdown ||
    field.type === FormFieldTypes.StaticImage
  ) {
    return
  }

  switch (field.type) {
    case FormFieldTypes.Switch:
    case FormFieldTypes.Checkbox:
      validation = z.boolean()
      break
    case FormFieldTypes.NumericInput:
    case FormFieldTypes.Slider:
      validation = z.coerce.number({ invalid_type_error: 'Value is required' })

      if (!isNil(field.minimumNumber)) {
        validation = validation.min(field.minimumNumber)
      }

      if (!isNil(field.maximumNumber)) {
        validation = validation.max(field.maximumNumber)
      }
      break
    case FormFieldTypes.Currency:
      validation = z.coerce.number({ invalid_type_error: 'Value is required' })

      if (!isNil(field.minimumNumber)) {
        validation = validation.min(field.minimumNumber)
      }

      if (!isNil(field.maximumNumber)) {
        validation = validation.max(field.maximumNumber)
      }
      break
    case FormFieldTypes.MultiSelect:
      validation = z.array(z.string())
      break

    case FormFieldTypes.Datepicker:
      const INITIAL_MSG = 'Date should be '
      let dateRangeMsg = INITIAL_MSG

      const minimumDate = field.minimumDate
        ? new Date(field.minimumDate)
        : field.pastDatesEnabled === false
        ? new Date()
        : undefined

      const maximumDate = field.maximumDate
        ? new Date(field.maximumDate)
        : field.futureDatesEnabled === false
        ? new Date()
        : undefined

      if (minimumDate) {
        dateRangeMsg += `after ${format(minimumDate, 'dd/MM/yyyy')}`
      }

      if (maximumDate) {
        dateRangeMsg += `${minimumDate ? ' and' : ''} before ${format(maximumDate, 'dd/MM/yyyy')}`
      }

      validation = z
        .string({
          invalid_type_error: 'Invalid date',
        })
        .refine(date => !DatetimeUtils.isValidDateFormat(date), {
          message: 'Invalid date format. Please use the DD/MM/YYYY format.',
        })
        .refine(
          value => {
            let isInvalid = false

            if (minimumDate) {
              isInvalid = isBefore(startOfDay(new Date(value)), startOfDay(minimumDate)) || isInvalid
            }

            if (maximumDate) {
              isInvalid = isAfter(startOfDay(new Date(value)), startOfDay(maximumDate)) || isInvalid
            }

            return !isInvalid
          },
          { message: dateRangeMsg },
        )
      break
    case FormFieldTypes.DateRangePicker:
      validation = z
        .tuple([z.date().nullable(), z.date().nullable()])
        .refine(([startDate, endDate]) => (startDate === null && endDate === null) || !isBefore(endDate!, startDate!), {
          message: 'Invalid date range.',
        })
      break

    case FormFieldTypes.Email:
      validation = z.string().refine(
        value => {
          return value === '' || emailRegex.test(value)
        },
        {
          message: 'Invalid email address',
        },
      )

      break
    case FormFieldTypes.PhoneNumber:
      validation = z
        .string()
        .length(10, {
          message: 'Phone number must be 10 digits long',
        })
        .refine(
          value => {
            if (value !== '' && !phoneNumberRegex.test(value)) {
              return false
            }

            return true
          },
          {
            message: 'Invalid phone number format',
          },
        )

      break
    case FormFieldTypes.URL:
      validation = z.union([z.literal(''), z.string().trim().url()])
      break
    case FormFieldTypes.Select:
      validation = z.string()
      break
    case FormFieldTypes.JSON:
      validation = z.any()
      break
    case FormFieldTypes.IconPicker:
      validation = z.array(z.string()).or(z.string())
      break
    case FormFieldTypes.YesNo:
      validation = z.boolean({
        invalid_type_error: 'Value is required',
      })
      break
    case FormFieldTypes.Address:
      validation = z.string()
      break
    case FormFieldTypes.Table:
      const tableValidationSchema: ValidationSchema = {}
      field.columns.forEach(column => {
        const nestedValidation = generateValidation({
          type: column.columnType as BasicField['type'],
          isRequired: false,
          isReadonly: false,
          name: column.columnName,
          jsonPath: column.columnName,
          key: column.columnName,
        } as BasicField) as z.ZodTypeAny

        tableValidationSchema[column.columnName] = nestedValidation
      })

      validation = z.array(z.object(tableValidationSchema)).optional()
      break
    case FormFieldTypes.GroupedInputs:
      const groupedInputSchema: ValidationSchema = {}
      field.fields.forEach(f => {
        const nestedValidation = generateValidation({
          type: f.fieldType as BasicField['type'],
          isRequired: false,
          isReadonly: false,
          name: f.fieldName,
          jsonPath: f.fieldName,
          key: f.fieldName,
        } as BasicField) as z.ZodTypeAny

        groupedInputSchema[f.fieldName] = nestedValidation
      })

      validation = z.array(z.object(groupedInputSchema)).optional()
      break
    default:
      validation = z.string()
  }

  if (field.isRequired) {
    const isValueValid = (value: string | boolean | number | []) =>
      isArray(value) ? value.length : value !== undefined && value !== null && value !== ''

    validation = (validation as z.ZodString).refine(isValueValid, {
      message: `Value is required for ${field.name}`,
    })
  } else {
    validation = validation.optional().nullable()
  }

  return validation
}

const cleanupNumericInput = (e: React.ChangeEvent<HTMLInputElement>) => {
  let value = e.target.value

  // Remove any negative sign.
  value = value.replace(/-/g, '')

  e.target.value = value
}

export const ValidationUtils = {
  initializeValidationSchema,
  generateValidation,
  cleanupNumericInput,
}
