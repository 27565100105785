import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputGroupProps,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react'
import { InfoTip } from '@unmand-systems/components'
import React, { useState } from 'react'
import { Control, Controller, ControllerRenderProps, FieldValues } from 'react-hook-form'
import { useFormAccessibility } from '../../context/FormAccessibilityContext'
import { StyleOptions } from '../../interfaces'
import { StyleUtils } from '../../utils'
import { CodeEditor, CodeLanguage, isCodeValid } from '../core/CodeEditor'

interface JsonFieldProps {
  formControlName: string
  control: Control
  error?: string
  isDisabled?: boolean
  isReadonly?: boolean
  isLabelHidden?: boolean
  isRequired?: boolean
  placeholder?: string
  helperText?: string
  title: string
  tooltipText?: string
  width?: string
  variant?: InputGroupProps['variant']
  hasNoOutline?: boolean
  isDynamicWidth?: boolean
  overrideControlName?: string
  style: NonNullable<StyleOptions>
}

export const JsonField: React.FC<JsonFieldProps> = ({
  formControlName,
  control,
  error,
  isReadonly,
  isDisabled,
  isLabelHidden,
  isRequired,
  placeholder,
  helperText,
  title,
  variant,
  hasNoOutline,
  isDynamicWidth,
  overrideControlName,
  style,
}) => {
  const { colors, inputs } = StyleUtils.getTheme(style)
  const placeholderColor = StyleUtils.getPlaceholderColor(colors.inputBgColor)
  const { isOpen, onClose, onOpen } = useDisclosure()
  const [jsonStringValue, setJsonStringValue] = useState<string>('')
  const [isValid, setIsValid] = useState<boolean>(true)
  const { getTabIndex } = useFormAccessibility()

  const initValue = (field: ControllerRenderProps<FieldValues, string>) => {
    if (!field.value || typeof field.value === 'string') {
      return
    }

    field.onChange(JSON.stringify(field.value))
  }

  return (
    <Controller
      name={overrideControlName ?? formControlName}
      control={control}
      render={({ field: controlField }) => {
        initValue(controlField)

        const inputWidth =
          isDynamicWidth && (controlField.value || jsonStringValue)?.length > 20
            ? `${Math.max((controlField.value?.length ?? jsonStringValue?.length ?? 0) * 9.5, 200)}px`
            : '100%'

        return (
          <>
            <FormControl isInvalid={!!error} isRequired={isRequired} maxW="100%" flex="1">
              {!isLabelHidden && (
                <FormLabel display="flex" alignItems="center" gap="1">
                  {title}
                  <span className="form__optional-indicator">
                    {!isRequired && inputs.hideRequiredAsterisk && '- Optional'}
                  </span>
                  {helperText && <InfoTip tooltipText={helperText} />}
                </FormLabel>
              )}
              <InputGroup height="initial" flexDir="column">
                <Input
                  px={variant === 'unstyled' ? 2 : undefined}
                  variant={variant}
                  {...(hasNoOutline ? { border: 0 } : {})}
                  resize={'none'}
                  {...controlField}
                  type={'text'}
                  placeholder={placeholder ?? 'Input value'}
                  onWheel={e => (e.target as HTMLElement).blur()}
                  bg={colors.inputBgColor}
                  color={colors.inputTextColor}
                  borderColor={variant === 'outline' ? inputs.borderColor : undefined}
                  _hover={{ bg: colors.inputBgColor }}
                  borderRadius={inputs.borderRadius}
                  sx={{
                    cursor: 'pointer',
                    '::placeholder': {
                      color: placeholderColor,
                      fontStyle: 'italic',
                    },
                  }}
                  tabIndex={getTabIndex(formControlName)}
                  isReadOnly
                  width={inputWidth}
                  _disabled={{ cursor: 'pointer' }}
                  onClick={() => {
                    if (isDisabled || isReadonly) {
                      return
                    }

                    try {
                      setJsonStringValue(JSON.stringify(JSON.parse(controlField.value ?? {}), null, 4))
                    } catch (e) {
                      setJsonStringValue('{}')
                    }
                    onOpen()
                  }}
                />
                {error && <FormErrorMessage>{error}</FormErrorMessage>}
              </InputGroup>
            </FormControl>
            <Modal isOpen={isOpen} onClose={onClose} size="5xl">
              <ModalOverlay />
              <ModalContent>
                <ModalHeader fontSize="md">Set JSON value for {title}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <Box h="500px">
                    <CodeEditor
                      value={jsonStringValue}
                      language={CodeLanguage.JSON}
                      onChange={value => {
                        const isValid = isCodeValid(value!, CodeLanguage.JSON)

                        setIsValid(isValid)
                        if (isValid) {
                          setJsonStringValue(value ?? '{}')
                        }
                      }}
                    />
                  </Box>
                </ModalBody>
                <ModalFooter gap={2}>
                  <Button colorScheme="gray" onClick={onClose}>
                    Cancel
                  </Button>
                  <Button
                    isDisabled={!isValid}
                    onClick={() => {
                      controlField.onChange(JSON.stringify(JSON.parse(jsonStringValue ?? '{}')))
                      onClose()
                    }}
                  >
                    Update
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          </>
        )
      }}
    ></Controller>
  )
}
